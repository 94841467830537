input[type="checkbox"] {
    display: none;
}

.checkbox {
    label {
        padding-left: 0px;
    }
}

.custom-checkbox {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    &:before {
        border: 1px solid $alto;
        content: '';
        display: inline-block;
        height: 17px;
        margin-right: 7px;
        width: 17px;
    }
    &:hover, &:focus {
        &:before {
            border: 2px solid $cerulean;
        }
    }
}

.custom-checkbox.checked {
    &:before {
        @include font-size(14);
        align-items: center;
        background-color: $cerulean;
        border: 1px solid $cerulean;
        color: $white;
        content: '\f00c';
        display: flex;
        font-family: $fontawesome;
        font-weight: lighter;
        justify-content: center;
    }
}