.loads-list-gridview {
    margin-top: 35px;
}
.adv-credits-cost {
    display: block;
    width: 100%;
    padding-top: 0.5em;
    font-weight: bold;
    font-size: 2em;
    text-align: right;
}

.adv-total-credit {
    display: block;
    width: 100%;
    font-size: 0.8em;
    color: darkgray;
    text-align: right;
}

.load-active-tag {
    color: green;
}

.load-inactive-tag {
    color:red;
}

.load-created-at-column-content,
.load-date-column-content,
.unload-city-column-content,
.load-column-content,
.car-transporter-created-at-column-content,
.car-transporter-available-from-column-content
{
    position: relative;
}

.shares.shares-counter,
.shares.shares-opened-counter {
    position: absolute;
    bottom: 10px;
    @include max-width-767 {
        position: relative;
        bottom: initial;
    }
}

.loads-list-gridview input[type="checkbox"] {
    display: inline-block;
}

.grid-header-dropdown {
    padding-right: 10px;
    border: none;
    background-color: transparent;
    color: #337ab7;
    position: relative;
}

.grid-header-dropdown-container {
    font-size: .875rem;
}

.grid-header-dropdown-container .select-arrow {
    right: 0px;
    top: inherit;
}

.page-size-dropdown .select-arrow {
    top: 10px !important;
}

.option-icon-div {
    margin-right: 8px;
}

.map {
    background-color: #fbfbfb;
}
