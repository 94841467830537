.intl-tel-input {
    width: 100%;
    .flag-container {
        width: 100%;
    }
    .country-list {
        @include font-size(14);
        font-weight: normal;
        width: 100%;
    }
}