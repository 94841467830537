.login-alert, .main-alert {
    #toast-container.toast-top-center {
        position: relative;
        margin: 0px;
        top: 0px;
        left: 0px;
        margin-bottom: 25px;
        @include font-size(14);
        box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
        z-index: 1;
        .toast {
            border-radius: 0px;
            max-width: 420px;
            width: 100%;
            padding: 20px 50px;
            &:hover, &:focus {
                box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.75);
            }
            .toast-close-button {
                outline: none;
                width: 0px;
                height: 0px;
                line-height: 0;
                @include font-size(30);
                font-weight: normal;
                text-indent: 0px;
                position: absolute;
                top: 50%;
                right: 35px;
                margin-top: -2px;
                &:hover, &:focus {
                    color: $white;
                }
            }
        }
    }
}

.main-alert {
    #toast-container.toast-top-center {
        .toast {
            max-width: 100%;
        }
    }
}