.select2-container--krajee {
    .popover {
        max-height: 500px;
        max-width: 500px;
    }
    .select2-selection--single {
        @include font-size(14);
        border-radius: 0;
        border: 1px solid $alto;
        box-shadow: none;
        line-height: 26px;
        min-height: 40px;
        outline: none;
        height: 40px;
        .select2-selection__arrow {
            display: none;
        }
        .select2-selection__rendered {
            pointer-events: none;
        }
    }
    .select2-search--dropdown {
        .select2-search__field {
            @include font-size(14);
            border-radius: 0;
        }
    }
    li.select2-results__option {
        @include font-size(14);
    }
    .select2-selection__clear {
        @include font-size(28);
        right: 10px;
        top: 1px;
    }
    .select2-selection--multiple {
        min-height: 40px;
        border-radius: 0;
        &:after {
            @include font-size(14);
            color: $secondarytext;
            content:"\f0d7";
            font-family: $fontawesome;
            margin-top: -9px;
            position: absolute;
            right: 12px;
            top: 50%;
        }
        .select2-selection__rendered {
            @include font-size(14);
            font-weight: normal;
            line-height: 38px;
            padding-right: 40px;
        }
        .select2-selection__choice {
            @include font-size(15);
            background-color: $alabaster2;
            border-radius: 0;
            border: 1px solid $alto2;
            line-height: 23px;
            padding: 2px 10px;
            margin: 4px 0 0 6px;
            @include max-width-767 {
                margin-left: 10px;
                padding: 7px 10px 7px 20px;
                width: 100%;
            }
        }
        .select2-selection__choice__remove {
            @include font-size(24);
            color: $secondarytext;
            margin: 0 0 0 5px;
        }
    }
    .select2-selection {
        border: 1px solid $alto;
    }
    .select2-dropdown {
        overflow-x: visible;
    }
}

.select2-dropdown {
    border-radius: 0px;
}

.select2-container {
    .select2-selection--single {
        .select2-selection__clear {
            position: absolute;
            right: 14px;
            top: 7px;
        }
    }
}

.select2-results__option {
    .fa-globe {
        position: relative;
        top: -4px;
    }
}

.select2__icon-text {
    position: relative;
}

.select2__icon {
    position: absolute;
    top: -2px;
    right: 0;
}
