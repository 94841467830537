.haulier-list-item-cover {
    width: 100%;
}

.haulier-list-item-logo-container {
    position: absolute;
}

.haulier-list-item-logo {
    bottom: -30px;
    left: 10px;
    position: absolute;
    margin-bottom: 0px;
    border-radius: 0px;
    width: 160px;
}

.haulier-list-item .row {
    margin: 310px 0px 70px 0px;
}

.haulier-list-item-button-row .haulier-text {
    font-weight: 600;
    margin-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 40px;
}

.haulier-list-item-button-row .haulier-rating {
    height: auto;
    padding-top: 10px;
}

.haulier-list-item-button-row {
    height: 50px;
}

.edit-haulier-btn {
    padding: 5px 10px !important;
}

.edit-haulier-btn i {
    margin-right: 0px !important;
}

#approve-images-haulier {
    margin-top: 5px;
    padding: 5px 15px;
    width: 100%;
    text-transform: uppercase;
}

#approve-images-haulier.disabled {
    opacity: 0.5;
    pointer-events: none;
    @include prefix-user-select(none);
    background-color: #ddd;
}
#approve-images-haulier.disabled:hover {
    background-color: #ddd;
}

.haulier-list-item-cover-row {
    position: relative;
}

.haulier-list-item-logo-container {
    position: relative;
}

.change-cover-btn {
    right: 10px;
    top: 10px;
}

.change-logo-btn {
    left: 125px;
    bottom: 10px;
}

.cropper-widget .new-photo-area {
    color: $primarytext;
    margin: 0px;
}

.cropper-widget .cropper-buttons {
    padding: 15px 0px;
}
