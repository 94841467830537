.export-csv-btn-wrapper {
    @include max-width-767 {
        width: 100%;
    }
    li {
        list-style: none;
    }
}

.export-page-select-row {
    margin-top: 30px;
    .page-size-dropdown {
        margin-top: 0px;
        @include max-width-767 {
            margin-bottom: 0px;
            margin-top: 20px;
        }
    }
}

.secondary-button.reset-filtration {
    margin-left: 10px;
    padding: 9.5px 25px;
    &:hover, &:focus {
        color: $primarytext;
        text-decoration: none;
    }
    @include max-width-767 {
        display: inline-block;
        margin: 15px auto 0px auto;
        text-align: center;
    }
}

.notice {
    @include font-size(14);
    color: $secondarytext;
    margin: 25px 20px 0px 25px;
}

.export-list-inline {
    display: inline-block;
    margin-right: 14px;
}