.nav-tabs {
    border-bottom: none;
}

.nav-tabs.nav-justified {
    li {
        a {
            border-bottom: none;
            border-radius: 0px;
            color: $disabledtext;
            font-weight: 600;
            &:hover, &:focus {
                background: none;
                border: 1px solid transparent;
                color: $secondarytext;
            }
            @include max-width-767 {
                border: 1px solid $alto;
                margin-bottom: 10px;
                &:hover, &:focus {
                    border: 1px solid $secondarytext;
                }
            }
            .svg-inline--fa {
                margin-right: 5px;
                @include max-width-1240 {
                    margin-right: auto;
                }
            }
        }
    }
    .active {
        a {
            border: none;
            border-bottom: 3px solid $cerulean;
            color: $primarytext;
            &:hover, &:focus {
                border: none;
                border-bottom: 3px solid $cerulean;
                color: $primarytext;
            }
            @include max-width-767 {
                border: 2px solid $cerulean;
                &:hover, &:focus {
                    border: 2px solid $cerulean;
                }
            }
        }
    }
}

.tab-content {
    margin-top: 10px;
}
