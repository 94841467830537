.social-buttons > a {
text-decoration: none;
font-size: 16px;
}

.social-buttons > a > button {
background-color: #e9e9e9;
padding: 10px;
border: 1px solid grey;
border-radius: 5px;
margin: 2px;
transition: .2s ease-out;
transition-delay: 0.3s;
}
.social-buttons > a > button:hover {
background-color: #ffe895;
}
.social-buttons > a > button > img {
height: 30px;
width: 30px;
}

.social-buttons .social-buttons-domain {
    text-align: left;
    margin-bottom: 20px;
}

.whatsapp-si {
color: rgb(0,211,37);
}
.facebook-si {
color: rgb(24, 119, 242);
}
.messenger-si {
color: rgb(68, 138, 255);
}
.email-si {
color: rgb(50,50,50);
}
.viber-si {
color: #6158af;
}
.viber-si > button > img {
margin: -8px;
height: 45px !important;
width: 45px !important;
}
.skype-si {
color: #0e78c8;
}
.sms-si {
color: rgb(50,50,50);
}

.load-preview-icon .svg-inline--fa {
    margin-right: 3px;
    padding: 2px 4px 0px 4px;
    border-radius: 20px;
    border: 2px solid $yelloworange;
    font-size: 1.2rem !important;
    font-weight: bolder;
    color: $yelloworange;
}

.load-icon-text-container {
    /*display: inline-flex;*/
    text-align: center;
    @include max-width-767 {
        display: inline-block;
    }

    div {
        display: inline-block;
        @include max-width-767 {
            display: block;
            margin-top: 3px;
        }
        margin: auto;
        line-height: 1em;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: $secondarytext;
    }
    .tooltip-inner {
        font-size: 10px;
        color: white;
        text-transform: none;
    }

    .icon-text  {
        width: 100%;
    }
}

.load-static-map-enlarge-link {
    position: relative;
    display: block;
    .svg-inline--fa {
        position: absolute;
        top: 4px;
        right: 4px;
        font-size: 17px;
        background-color: #000;
        opacity: 0.3;
        padding: 3px;
        color: white;
    }

    &:hover .svg-inline--fa{
        opacity: 0.6;
    }

    @include min-width-992 {
        width: 270px;
    }
}

.load-static-map-mobile-view {
    display: none !important;
    @include max-width-767 {
        display: block !important;
    }

    img {
        width: 100%;
    }
}

#load-large-static-map {
    .modal-body img {
        width: 100%;
    }
    .modal-body button {
        margin-top: 10px;
        width: 100%;
    }
}

.load-price-tag,
.load-title-price {
    color: $secondarytext;
    font-size: 14px;
    font-weight: 500;
    display: inline;
}

.load-offer-icon .svg-inline--fa,
.load-return-icon .svg-inline--fa {
    color: $pictonblue;
    font-size: 1.0rem !important;
    border-radius: 20px;
    border: 2px solid $pictonblue;
    padding: 4px 6px 4px 3px;
    margin-right: 3px;
    margin-top: 4px;
    margin-bottom: 3px;
    @include max-width-767 {
        font-size: 1.5rem !important;
        border-radius: 118px;
        border: 3px solid #3498db;
        padding: 4px 7px 5px 4px;
        /*font-size: 2rem !important;
        border-radius: 24px;
        border: 3px solid #3498db;
        padding: 3px 7px 0px 0px;
        margin-top: 1px;*/

    }
}

.load-link-icon {
    .svg-inline--fa {
        display: inline;
        font-size: 15px !important;
        margin-right: 3px;
    }
    .fa-circle {
        color: $logoblue;
        font-size: 30px !important;
    }

    .fa-stack {
        margin-left: -2px;
        height: 35px;
        line-height: 35px;
    }
}

.load-link-icon {
    @include max-width-767 {
        .fa-stack {
            height: 3em;
            width: 3em;
            line-height: 3em;
            .fa-circle {
                font-size: 46px !important;
                margin-top: 4px;
            }
            .fa-share-alt {
                font-size: 2rem !important;
                margin: 3px 0px 4px -2px;
            }
        }
    }
}

.load-return-icon .svg-inline--fa {
    color: $yelloworange;
    border-color: $yelloworange;
    @include max-width-767 {
        border: 3px solid $yelloworange;
        font-size: 1.75rem !important;
        padding: 4px 6px 4px 5px;
        margin-top: 6px;
        margin-bottom: 0;
    }
}
