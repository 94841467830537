.load-list-header {
    color: $secondarytext;
    font-weight: 550;
}

.prefill-time {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-family: monospace;
    font-weight: 400;
    color: rgba(128,128,128,.54);
    font-size: 14px;
}

.load-list-item {
    margin-bottom: 10px;
    position: relative;

    .load-list-item-box {
        padding: 10px;
        border-radius: 10px;
        box-shadow: 1px 4px 5px rgba(0, 0, 0, .14);
        background: white;
        font-weight: 500;
    }

    .load-list-item-map-inner-container {
        float: right;
        font-size: 13px;
        font-weight: normal;
        line-height: 20px;
        color: $secondarytext;

        .load-item-time {
            margin-right: -4px;
            margin-bottom: -9px;
            text-align: right;
            font-family: monospace;
            font-weight: normal;
            color: lighten($secondarytext, 50%);
        }
    }

    .load-list-item-map-mobile {
        img {
            width: 100%;
        }
    }

    .load-list-item-route-arrow img {
        height: 19px;
        width: 18px;
    }

    .load-list-item-map-container {
        padding-right: 0px;
        padding-left: 0px;
    }

    .load-list-item-buttons-container {
        position: absolute;
        right: 0px;
        bottom: 0px;
    }

    .load-list-item-buttons-inner-container {
        position: absolute;
        bottom: 0;
    }

    .load-list-item-previews-container {
        font-size: 14px;
    }

    @media (max-width: 767px) {
        position: relative;
        margin-left: 0px;
        margin-right: 0px;

        .load-list-item-title-container {
            position: relative !important;
            min-height: unset !important;
        }

        .load-list-item-box {
            position: relative !important;
            min-height: unset !important;
            .load-list-item-title {
                position: relative !important;
                min-height: unset !important;
                top: unset !important;
                left: unset !important;
                transform: unset !important;
                padding-top: 10px;
                padding-bottom: 20px;
                font-size: 16px;
                .load-price-tag {
                    display: inline;
                }
                span {
                    padding-left: 4px;
                }
            }
        }


        .load-list-item-route-container.visible-xs {
            margin-top: 15px;
            min-height: unset !important;

            .load-list-item-route-arrow {
                display: block;
                margin: 0px 5px !important;
                height: 20px;
                img {
                    position: relative !important;
                    margin-top: -15px;
                }
            }

            .load-list-item-route {
                position: relative !important;
                min-height: unset !important;
                top: unset !important;
                left: unset !important;
                transform: unset !important;
            }
        }
    }

    .load-list-item-route-container.hidden-sm {
        height: 100%;
        min-height: 170px;

        .load-list-item-route-to,
        .load-list-item-route-from,
        .load-list-item-route-arrow {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            padding-left: 0px !important;
            padding-right: 0px !important;

            font-size: 14px;
            line-height: 18px;

            div {
                display: block;
                line-height: 22px;
            }
        }

        .load-list-item-route-arrow {
            left: 41.67%;
            text-align: center;
        }

        .load-list-item-route-from {
            padding-right: 10px;
            margin-left: 5px;
        }
        .load-list-item-route-to {
            left: 50%;
            padding-left: 10px;
            margin-right: 5px;
        }
    }

    .load-list-item-route-container.visible-sm {
        min-height: 170px;

        .load-list-item-route-arrow {
            display: block;
            margin: 5px;
            height: 18px;
            img {
                position: absolute;
            }
        }

        .load-list-item-route {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            margin-left: 10px;
        }

        .load-list-item-route-to,
        .load-list-item-route-from {
            padding-left: 30px;
            line-height: 22px;
            div {
                display: block;
            }
        }

        .load-list-item-route-from {
            /*margin-bottom: -12px;*/

        }

        .load-list-item-route-to {
            /*margin-top: -12px;*/
        }
    }

    .load-list-item-companyinfo-container {
        padding-left: 0;
        padding-right: 0;
        @media (max-width: 767px) {
            padding-left: 15px;
            padding-right: 15px;
        }
        .load-icon-text-container {
            margin-top: 8px;
        }

        a.load-owner-subscribed:visited,
        a.load-owner-subscribed:link {
            color: $seagreen;
        }

        a.load-owner-subscribed:hover {
            color: $seagreen2;
        }
    }

    .load-list-item-status-container {
        @media (max-width: 767px) {
            float: left;
            width: 100%;
            .load-active-tag,
            .option-icon-div,
            .announcement-text,
            .load-inactive-tag {
                float: left;
                margin-right: 8px;
            }
        }
    }

    .load-list-item-title-container {
        height: 100%;
        min-height: 170px;
        .load-list-item-title {
            position: absolute;
            top: 50%;
            transform: translate(0, -85%);
            line-height: 20px;
        }
        .load-date-element {
            font-size: 0.875rem;
            font-weight: 400;
            color: rgba(0,0,0,.54);
        }
        .load-date-time-element {
            position: absolute;
            bottom: 0;
        }
        .time-element {
            display: inline;
            font-family: monospace;
            font-weight: 400;
            color: rgba(128,128,128,.54);
        }
    }

    .center-children {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-height: 168px;
        @media (max-width: 767px) {
            min-height: 10px;
            justify-content: unset;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .item-checkbox {
        .load-checkbox {
            display: block;
        }
    }

    .load-labels {
        position: absolute;
        bottom: 0;
        left: -10px;
        .load-label {
            border-right-width: 7px;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-right-style: solid;
            color: $white;
            padding: 1px 6px 1px 25px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            min-width: 136px;
        }

        .label-high-demand {
            background-color: $pictonblue;
            border-color: $royalblue;
        }

        .label-best-price {
            background-color: $treepoppy;
            border-color: $yelloworange;
            margin-top: 1px;
        }
    }

    @media (max-width: 767px) {
        .load-labels {
            bottom: 30px;
            z-index: 1;
        }
    }

    @media (min-width: 767px) {
        .load-labels {
            bottom: 22px;
        }
    }
}

.load-list-hovering-date {
    text-align: center;
    z-index: 1;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    .badge {
        position: absolute;
        padding-top: 3px;
        padding-bottom: 4px;
        left: calc(50% - 77px);
        transform: translate(calc(50% - 40px) 0);
        top: 8px;
        @media (max-width: 767px) {
            top: 18px;
        }
    }
}

.summary {
    margin-bottom: 15px;
}

.load-route-info {
    font-size: 12px;
    color: rgba(0,0,0,.54);
    font-weight: 500;
    margin-top: -2px;
    line-height: 20px;
}

.load-car-condition-normal,
.load-car-condition-not-normal
{
    display: inline-block;
    vertical-align: text-top;
}

.load-car-condition-not-normal {
    position: relative;
    transform: rotate(-120deg);
    padding-right: 3px;
    font-size: 18px;
    margin-top: -1px;
}

.expanded-load-preview-content-mobile {
    .preview-col-load-code {
        position: relative;
        display: contents;
        right: 0px;
    }
    .search-results-load-code {
        right: 15px;
    }
}

.load-preview-button-row {
    padding: 15px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.load-preview-mobile-modal,
.my-loads-menu-mobile-modal
{
    .modal-dialog,
    .modal-content {
        width: 100% !important;
        height: 100% !important;
        margin: 0;
        top: 0;
        left: 0;
    }

    .modal-dialog {
        margin: 0px !important;
    }
    .modal-content {
        border-top-width: 0px;
    }

    .modal-header {
        display: none;
    }

    .modal-body {
        height: 100%;
    }

}

@media (max-width: 767px) {
    .preview-col-load-code {
        position: absolute;
        top: 10px;
    }

    .load-preview-contact-column,
    .load-preview-data {
        line-height: 1.5 !important;
    }

    .load-preview-load-title {
        display: none;
    }

    .load-preview-load-info {
        border: solid 2px lighten($secondarytext, 50%);
        border-radius: 10px;
        margin-right: 10px;
        padding: 10px 15px 10px 10px;
        margin-left: 10px;
        margin-top: 20px;
    }

    .content {
        padding: 10px;
        position: absolute;
        bottom: 100px;
        .search-results-load-code {
            display: none;
        }
        .row {
            font-weight: normal;
        }
    }

    .search-results-load-code {
        right: 15px;
    }

    .cartransport-preview-container .row.text-center {
        margin-right: 10px;
        padding-right: 15px;
    }

    .load-preview-load-info-date {
        width: 100%;
        text-align: right;
        font-size: .875rem;
        font-weight: 400;
        color: rgba(128,128,128,.54);
    }

    .load-list-item-date {
        width: 100%;
        font-size: .875rem;
        font-weight: 500;
        color: rgba(0,0,0,.54);
    }

    .load-list-item-title {
        position: relative !important;
        min-height: unset !important;
        top: unset !important;
        left: unset !important;
        transform: unset !important;
        padding-top: 28px;
        padding-bottom: 20px;
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 0;
        padding-top: 0;
        .load-price-tag {
            display: inline;
            color: $secondarytext;
            text-align: right;
        }
        span {
            padding-left: 4px;
        }
    }

    .load-offer-form {
        .input-group,
        .input-group input {
            width: 100%;
        }
        button {
            margin-top: -25px;
        }
    }
}

@media (min-width: 768px) {
    .load-list-item-date {
        position: absolute;
        width: 100%;
        font-size: .875rem;
        font-weight: 500;
        color: rgba(0,0,0,.54);
    }

    .load-list-item-route-container {
        padding-top: 20px;
    }
}

.load-title-price {
    display: block;
    span {
        font-weight: bold;
    }
}

.load-details-tag {
    font-size: 14px;
    font-weight: 600;
    color: rgba(0,0,0,.54);
    padding-left: 28px;
}

.advertised-load-cars {
    position: absolute;
    top: -10px;
    left: -10px;
    border: solid #ffab35 2px;
    border-radius: 16px;
    font-size: 14px;
    color: #ffab35;
    padding: 0 1px;
    font-weight: bold;
    background-color: #fff;
    span {
        font-size: 12px;
        margin-left: 1px;
        margin-right: -1px;
    }
    .load-car-condition-normal {
        font-size: 20px;
        margin-top: -1px;
    }
}

.load-list-filter-wrapper {
    padding: 10px;
    border-radius: 10px;
    box-shadow: 1px 4px 5px rgba(0,0,0,.14);
    background-color: lighten($secondarytext, 90%);
    font-weight: 700;

    .searchRadius {
        label {
            margin-right: 20px;
            font-size: .875em;
            color: rgba(0,0,0,.54);
            font-weight: normal !important;
        }
    }
    .control-label {
        margin-top: 5px;
        text-align: right;
    }
    .select-addon .svg-inline--fa {
        top: 40px;
    }
    @media (max-width: 767px) {
        .control-label {
            text-align: left;
        }
    }
}

.no-padding-sm {
    @media (max-width: 767px) {
        padding: 0px;
    }
}

.load-city-name,
.city-item-postal {
    display: inline !important;
}


.load-preview-button-desktop-row {
    margin-top: 20px;
    .load-icon-text-container {
        min-width: 33%;
        float: left;

        .load-link-icon .svg-inline--fa.fa-circle {
            font-size: 32px !important;
        }

        .icon-text {
            width: auto;
            color: rgba(0, 0, 0, .87);
            font-family: "Open Sans", sans-serif, Arial;
            font-size: 16px;
            text-transform: none;
        }
    }
    .load-icon-text-container.buffer {
        max-width: 33%;
        width: auto;
        min-width: auto;
    }
}

.load-preview-icon .svg-inline--fa {
    margin-right: 3px;
    padding: 2px 4px 0px 4px;
    border-radius: 20px;
    border: 2px solid $yelloworange;
    font-size: 1.2rem !important;
    font-weight: bolder;
    color: $yelloworange;
}

.loads-list-custom-summary {
    .summary {
        float: left;
    }
    #stats {
        float: right;
        color: rgba(0,0,0,.54);
    }
}
